<template>
  <div>
    <form-section
      :title="$t('courierSet.additionalServices')"
      :tooltip="{ icon: 'mdi-help-circle-outline' }"
    >
      <template v-slot:message>
        <AdditionalServicesTooltipMessage />
      </template>
      <div
        v-for="serv in courierAddtionalServices"
        :key="serv.id"
      >
        <service-field
          :id="serv.id"
          :label="serv.name"
          :remark="serv.remark"
          :messages="serv.description"
          :code="serv.serviceId"
          :selected-service="isSelected(serv)"
          :active-service="isActive(serv)"
          :visible-service="isVisible(serv)"
          :parameters="serv.parameters"
          @@activate="activate"
          @@deactivate="deactivate(serv)"
          @@update="updateService"
        >
          <div
            v-if="serv.id === 2 && isSelected(serv)"
            class=""
          >
            <v-container>
              <v-row>
                <v-col>
                  <text-field
                    v-model="getInsurance.amount"
                    :title="$t('courierSet.amount')"
                    :disabled="!isSelected(serv)"
                    :rules="isSelected(serv) ? 'required|double:2|greaterThanZero' : ''"
                  />
                </v-col>
                <v-col>
                  <select-field
                    v-model="getInsurance.currency"
                    :filed-items="currencies"
                    :title="$t('courierSet.currency')"
                    :disabled="!isSelected(serv)"
                    :rules="isSelected(serv) ? 'required' : ''"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-checkbox
                    v-model="getInsurance.statement"
                    class="mt-0"
                    color="primary lighten-1"
                  >
                    <template v-slot:label>
                      <span :class="{ 'text--primary-lighten1': getInsurance.statement }">
                        {{ $t('courierSet.statement') }}
                      </span>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </service-field>
      </div>
    </form-section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import additionalServicesMixin from '@/mixins/additionalServicesMixin';
import AdditionalServicesTooltipMessage from '@/components/tooltips/contents/AdditionalServicesTooltipMessage.vue';
import { suusAdditionalServices as courierAddtionalServices } from '@/constants/Speditors/Suus/AddtionalServices';
import { currencies } from '../../../../constants/Speditors/Suus/Currencies';

export default {
  name: 'AdditionalServices',
  components: {
    AdditionalServicesTooltipMessage,
  },
  mixins: [additionalServicesMixin],
  props: {
    isNew: { type: Boolean, default: null },
  },
  data: () => ({
    additionalServices: [],
    courierAddtionalServices,
    unchangedSet: [],
    currencies,
  }),
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
    getInsurance() {
      return this.getConfigurationSet().suusDefaultValues.insurance;
    },
    getCashOnDelivery() {
      return this.getConfigurationSet().suusDefaultValues.cod;
    },
  },
  methods: {
    ...mapActions('spedition', ['setAdditionalServices']),
    update() {
      const state = this.additionalServices === this.unchangedSet ? 1 : 3;
      const serviceParameters = {
        clientEntityState: this.isNew || !this.id ? 4 : state,
        id: this.id,
        tenantId: this.oidcUser.tenantId,
        services: this.additionalServices,
      };
      this.setAdditionalServices({ additionalServices: serviceParameters });
    },
    clearAdditionalData(service) {
      switch (service.id) {
        case 2:
          this.getInsurance.amount = 0;
          this.getInsurance.currency = '';
          this.getInsurance.statement = false;
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
