export const suusPackage = {
  clientEntityState: 4,
  tenantId: '',
  id: '',
  symbol: '',
  quantity: '',
  weightKg: '',
  lengthCm: '',
  widthCm: '',
  heightCm: '',
  returnable: 0,
  stackable: false,
};
