import { suusDefaultValues } from './SuusDefaultValues';

export const Suus = {
  clientEntityState: 4,
  id: '',
  tenantId: '',
  clientId: '',
  configurationName: '',
  password: '',
  courierCode: 'suus',
  integration: 'Suus',
  skipShipmentGeneration: true,
  requireLabel: true,
  labelSource: 'NUBOWMS',
  courier: '',
  login: '',
  urlAddress: '',
  documentSymbol: '',
  suusDefaultValues,
  additionalServices: { services: [] },
  fieldsMappings: { mappings: [] },
};
