<template>
  <div>
    <form-section :title="$t('courierSet.setData')">
      <v-row>
        <v-col>
          <text-field
            v-model="getAuthorizationData.configurationName"
            :title="$t('courierSet.configurationName')"
            rules="required|not_custom_shipment"
          />
        </v-col>
        <v-col>
          <text-field
            v-model="getAuthorizationData.id"
            :title="$t('courierSet.configurationId')"
            rules="required"
            :disabled="true"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <select-field
            v-model="getAuthorizationData.courierCode"
            :title="$t('courierSet.deliverySpeditor')"
            rules="required"
            :filed-items="SHIPMENT_METHOD_CODES"
            disabled
          />
        </v-col>
      </v-row>
    </form-section>

    <form-section :title="$t('courierSet.authData')">
      <v-row>
        <v-col>
          <text-field
            v-model="getAuthorizationData.login"
            :title="$t('courierSet.login')"
            rules="required"
          />
        </v-col>
        <v-col>
          <text-field
            v-model="getAuthorizationData.password"
            :title="$t('courierSet.password')"
            type="password"
            rules="required"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0">
          <text-field
            v-model="getAuthorizationData.urlAddress"
            :title="$t('courierSet.urlAddress')"
            :rules="'required|url'"
          />
        </v-col>
        <v-col class="pt-0">
          <text-field
            v-model="getAuthorizationData.masterFid"
            :title="$t('courierSet.masterFid')"
            rules="required|numeric"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <select-field
            v-model="getAuthorizationData.payerType"
            :title="$t('courierSet.shippingPaymentType')"
            rules="required"
            :filed-items="payerTypes"
          />
        </v-col>
      </v-row>
    </form-section>
  </div>
</template>

<script>
import { payerTypes } from '@/constants/Speditors/DPD/PayerTypes';
import { mapGetters } from 'vuex';
import { SHIPMENT_METHOD_CODES } from '@/constants/ShipmentMethodCodes.js';

export default {
  props: {
    isNew: { type: Boolean, default: false },
  },
  data() {
    return {
      SHIPMENT_METHOD_CODES,
      payerTypes,
    };
  },
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
    getAuthorizationData() {
      return this.getConfigurationSet();
    },
  },
};
</script>

<style scoped></style>
