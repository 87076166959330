import { DHLExpressDefaultValues } from './DHLExpressDefaultValues';

export const DHLExpress = {
  clientEntityState: 4,
  tenantId: '',
  id: '',
  clientId: '',
  configurationName: '',
  password: '',
  courier: '',
  courierCode: 'dhlexpress',
  integration: 'DHLExpress',
  apiKey: '',
  apiSecret: '',
  url: '',
  additionalServices: { services: [] },
  dhlExpressDefaultValues: DHLExpressDefaultValues,
};
