export const OnDemandDelivery = {
  clientEntityState: 4,
  tenantId: '',
  id: '',
  deliveryOption: '',
  location: '',
  specialInstructions: '',
  gateCode: '',
  whereToLeave: '',
  neighbourName: '',
  neighbourHouseNumber: '',
  authorizerName: '',
  servicePointId: '',
  requestedDeliveryDate: '',
};
