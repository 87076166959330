import { upsDefaultValues } from './UpsDefaultValues';

export const UPS = {
  clientEntityState: 4,
  id: '',
  tenantId: '',
  clientId: '',
  configurationName: '',
  password: '',
  courier: '',
  courierCode: 'ups',
  integration: 'UPS',
  additionalServices: {
    services: [],
  },
  apiClientId: '',
  apiClientSecret: '',
  token: '',
  refreshToken: '',
  customerNumber: '',
  url: '',
  serviceCode: '',
  labelFormat: 'GIF',
  upsDefaultValues,
};
