<template>
  <div>
    <vee-field
      v-slot="{ errors }"
      :rules="rules"
    >
      <v-text-field
        v-model="model"
        v-bind="$attrs"
        :label="requredLabel"
        :hint="hint"
        :disabled="disabled"
        :type="type"
        :error-messages="errors"
        persistent-placeholder
        outlined
        dense
      />
    </vee-field>
  </div>
</template>
<script>
import Vue from 'vue';
import { cloneDeep as _cloneDeep } from 'lodash-es';

export default Vue.extend({
  props: {
    title: { type: String, default: null },
    hint: { type: String, default: null },
    fieldId: { type: String, default: 'false' },
    rules: { type: String, default: '' },
    type: { type: String, default: '' },
    disabled: { type: Boolean, dafult: false },
    value: {
      type: [String, Number],
      default: '',
    },
  },
  computed: {
    requredLabel() {
      if (!this.title) return '';
      return this.rules.includes('required') ? `${this.title} *` : this.title;
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', _cloneDeep(value));
      },
    },
  },
});
</script>

<style scoped>
::v-deep .v-input__slot {
  background-color: white !important;
  max-width: 100%;
}

::v-deep.v-select .v-chip {
  background-color: white;
  max-width: 100%;
}
::v-deep.v-chip__content {
  background-color: white;
}
::v-deep.v-list {
  background-color: none;
}
</style>
