<template>
  <div>
    <form-section :title="$t('courierSet.prcelContent')">
      <v-row>
        <v-col>
          <text-field
            v-model="getConfigurationValues.reference"
            :title="$t('courierSet.referenceNumber')"
          />
        </v-col>

        <v-col>
          <text-field
            v-model="getConfigurationValues.descriptionOfGoods"
            :title="$t('courierSet.descriptionOfGoods')"
            rules="required"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <text-field
            v-model="getConfigurationValues.remarks"
            :title="$t('courierSet.remarks')"
          />
        </v-col>
      </v-row>
    </form-section>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  data: () => ({}),
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
    getConfigurationValues() {
      return this.getConfigurationSet().suusDefaultValues.header;
    },
  },
});
</script>
