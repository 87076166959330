import { header } from './Header';
import { suusPackage } from './Package';
import { adrService } from './AdrService';
import { cod } from './Cod';
import { insurance } from './Insurance';

export const suusDefaultValues = {
  clientEntityState: 4,
  tenantId: '',
  id: '',
  clientId: '',
  header,
  package: suusPackage,
  adrService,
  cod,
  insurance,
};
