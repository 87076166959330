import { render, staticRenderFns } from "./ContentAndComment.vue?vue&type=template&id=183d2c3e"
import script from "./ContentAndComment.vue?vue&type=script&lang=js"
export * from "./ContentAndComment.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports