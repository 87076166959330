export const insurance = {
  clientEntityState: 4,
  id: '',
  tenantId: '',
  amount: 0,
  currency: '',
  additionalCost: 0,
  productType: '',
  strikeRisk: false,
  warRisk: false,
  statement: false,
};
