<template>
  <configuration-set
    v-if="!!getConfigurationSet()?.courier"
    :components="components"
    :speditor="speditor"
    :current-component="currentComponent"
    :loading="loading"
    @@changeComponent="changeComponent"
    @@save="save"
  >
    <template v-slot:content>
      <ServiceAuthorization
        v-show="currentComponent === 'ServiceAuthorization'"
        :is-new="isNew"
      />
      <additional-services
        v-show="currentComponent === 'AdditionalServices'"
        :is-new="isNew"
      />

      <additional-fields
        v-show="currentComponent === 'AdditionalFields'"
        :is-new="isNew"
      />

      <label-settings
        v-show="currentComponent === 'LabelSettings'"
        :is-new="isNew"
      />
    </template>
  </configuration-set>
</template>

<script>
import ConfigurationSet from '@/components/shared/ConfigurationSet.vue';
import configurationMixin from '@/mixins/configurationMixin';
import { OrlenPaczka } from '@/constants/Speditors/OrlenPaczka/configuration/OrlenPaczka';
import ServiceAuthorization from '@/views/Speditors/OrlenPaczka/Forms/ServiceAuthorization.vue';
import AdditionalServices from '@/views/Speditors/OrlenPaczka/Forms/AdditionalServices.vue';
import LabelSettings from '@/views/Speditors/OrlenPaczka/Forms/LabelSettings.vue';
import AdditionalFields from '@/views/Speditors/OrlenPaczka/Forms/AdditionalFields.vue';
import { v4 as uuidv4 } from 'uuid';
import { TEST_ORLEN_PACZKA_URL } from '@/constants/Speditors/testApi.js';
import { PROD_ORLEN_PACZKA_URL } from '@/constants/Speditors/prodApi.js';

export default {
  components: {
    ConfigurationSet,
    ServiceAuthorization,
    LabelSettings,
    AdditionalServices,
    AdditionalFields,
  },
  mixins: [configurationMixin],
  data: () => ({
    speditor: 'OrlenPaczka',
    currentComponent: 'ServiceAuthorization',
    components: [
      { code: 'ServiceAuthorization', name: 'courierSet.serviceAuthorizationName' },
      { code: 'AdditionalServices', name: 'courierSet.additionalServices' },
      { code: 'LabelSettings', name: 'courierSet.labelSettings' },
      { code: 'AdditionalFields', name: 'courierSet.additionalFields' },
    ],
  }),
  computed: {
    getUrlAddress() {
      if (process.env.VUE_APP_BUILD_TARGET_ENVIRONMENT === 'production') {
        return `https://${PROD_ORLEN_PACZKA_URL}/WebServicePwRProd/WebServicePwR.asmx?wsdl`;
      }
      return `https://${TEST_ORLEN_PACZKA_URL}/WebServicePwR/WebServicePwR.asmx?WSDL`;
    },
  },
  methods: {
    setNewConfiguration() {
      OrlenPaczka.tenantId = this.oidcUser.tenantId;
      OrlenPaczka.id = uuidv4();
      OrlenPaczka.url = this.getUrlAddress;
      this.setConfigSet({ response: OrlenPaczka });
    },
  },
};
</script>
