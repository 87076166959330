<template>
  <v-card
    elevation="0"
    class="pa-3"
    style="text-align: center"
  >
    <div class="text-left">
      <span class="text-h5 text--primary-lighten1 font-weight-bold">
        {{ $t('courierSet.upsAuthorization.title') }}
      </span>
    </div>

    <v-divider class="my-2" />

    <div class="inputs-wrapper">
      <text-field
        v-model="apiClientId"
        :title="$t('courierSet.clientId')"
        rules="required"
        hide-details
      />

      <text-field
        v-model="apiClientSecret"
        :title="$t('courierSet.clientSecret')"
        type="password"
        rules="required"
        hide-details
      />
    </div>

    <div
      v-if="loading"
      class="d-flex align-center justify-center pb-4"
    >
      <v-progress-circular
        size="40"
        color="primary lighten-1"
        class="mr-4"
        indeterminate
      />
      <div class="text-left">
        <span class="d-block text--primary-lighten1 font-weight-bold">
          {{ $t('courierSet.upsAuthorization.generateTokenMessage') }}...
        </span>
        <span> {{ $t('courierSet.upsAuthorization.waitForToken') }}</span>
      </div>
    </div>

    <v-divider class="my-3" />

    <div class="d-flex justify-end">
      <v-btn
        color="primary lighten-1"
        elevation="0"
        class="mr-2"
        outlined
        rules="required"
        @click="onCancel"
      >
        {{ $t('actions.cancel') }}
      </v-btn>
      <v-btn
        v-if="!loading"
        color="primary lighten-1"
        elevation="0"
        :disabled="!apiClientId || !apiClientSecret"
        @click="onGenerateToken"
      >
        {{ $t('courierSet.upsAuthorization.generateToken') }}
      </v-btn>
      <v-btn
        v-else
        color="primary lighten-1"
        elevation="0"
        :disabled="true"
      >
        <v-progress-circular
          size="18"
          color="white"
          class="mr-2"
          indeterminate
        />
        {{ $t('courierSet.upsAuthorization.onGenerate') }}
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import Vue from 'vue';
import TextField from '@/components/inputs/TextField.vue';
import { upsAuthorizationService } from '@/services/spedition/upsAuthorization.js';

export default Vue.extend({
  components: {
    TextField,
  },
  props: {
    redirectUrl: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    apiClientId: '',
    apiClientSecret: '',
  }),
  methods: {
    async onGenerateToken() {
      const { code } = this.$route.query;
      const payload = {
        upsEnv: 'TEST',
        code,
        redirectUri: this.redirectUrl,
        clientId: this.apiClientId,
        clientSecret: this.apiClientSecret,
      };
      try {
        this.loading = true;
        const { data } = await upsAuthorizationService.getUpsAuthorizationToken(payload);

        const { accessToken, refreshToken, responseStatus, upsResponse } = data;
        if (responseStatus !== 200) throw Error(upsResponse);
        this.$emit('@setAuthorizationData', {
          accessToken,
          refreshToken,
          clientId: this.apiClientId,
          clientSecret: this.apiClientSecret,
          environment: 'PROD',
        });
      } catch (err) {
        console.error(err);
        Vue.prototype.$snotify.error(err, { timeout: 0 });
      } finally {
        this.loading = false;
      }
    },

    onCancel() {
      this.$router.push('/organizationConfigurationSets');
    },
  },
});
</script>

<style scoped lang="scss">
.inputs-wrapper {
  display: grid;
  grid-template-columns: auto auto;
  gap: 4%;
  margin: 24px 0px;
}
</style>
