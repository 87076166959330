import { OrlenPaczkaDefaultValues } from '@/constants/Speditors/OrlenPaczka/configuration/OrlenPaczkaDefaultValues.js';

export const OrlenPaczka = {
  clientId: '',
  configurationName: '',
  clientEntityState: 4,
  tenantId: '',
  id: '',
  courier: '',
  courierCode: 'orlenPaczka',
  integration: 'OrlenPaczka',
  additionalServices: '',
  packagings: '',
  login: '',
  url: '',
  printAddress: 0,
  printFormat: 0,
  fieldsMappings: { mappings: [] },
  defaultValues: OrlenPaczkaDefaultValues,
};
