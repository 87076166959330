<template>
  <div class="action-row pa-2 rounded">
    <v-btn
      elevation="0"
      :disabled="invalid"
      @click="$emit('@save')"
    >
      {{ $t('courierSet.save') }}
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    invalid: { type: Boolean, default: true },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/utilities/variables';

.action-row {
  display: flex;
  align-self: center;
  justify-content: flex-end;
  padding: 5px;
  background-color: white;
  max-width: min-content;

  .v-btn {
    background-color: $primary-lighten1 !important;
    margin-right: 0px;
    color: white;
  }
}
</style>
