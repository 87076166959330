<template>
  <div>
    <form-section :title="$t('courierSet.setData')">
      <v-row>
        <v-col>
          <text-field
            v-model="getConfigurationValues.configurationName"
            :title="$t('courierSet.configurationName')"
            rules="required|not_custom_shipment"
          />
        </v-col>
        <v-col>
          <text-field
            v-model="getConfigurationValues.id"
            :title="$t('courierSet.configurationId')"
            rules="required"
            :disabled="true"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <select-field
            v-model="getConfigurationValues.courierCode"
            :title="$t('courierSet.deliverySpeditor')"
            rules="required"
            :filed-items="SHIPMENT_METHOD_CODES"
            disabled
          />
        </v-col>
      </v-row>
    </form-section>

    <form-section :title="$t('courierSet.authData')">
      <v-row>
        <v-col>
          <text-field
            v-model="getConfigurationValues.login"
            :title="$t('courierSet.login')"
            rules="required"
          />
        </v-col>
        <v-col>
          <text-field
            v-model="getConfigurationValues.password"
            :title="$t('courierSet.apiKey')"
            type="password"
            rules="required"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <text-field
            v-model="getConfigurationValues.url"
            :title="$t('courierSet.urlAddress')"
            :rules="'required|url'"
          />
        </v-col>
      </v-row>
    </form-section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SHIPMENT_METHOD_CODES } from '@/constants/ShipmentMethodCodes.js';

export default {
  props: {
    isNew: { type: Boolean, default: false },
  },

  data: () => ({
    SHIPMENT_METHOD_CODES,
  }),

  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
    getConfigurationValues() {
      return this.getConfigurationSet();
    },
  },
};
</script>

<style scoped></style>
