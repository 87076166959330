export const currencies = [
  {
    id: '0',
    name: 'PLN',
  },
  {
    id: '1',
    name: 'EUR',
  },
  {
    id: '2',
    name: 'USD',
  },
  {
    id: '3',
    name: 'CHF',
  },
  {
    id: '4',
    name: 'SEK',
  },
  {
    id: '5',
    name: 'NOK',
  },
  {
    id: '6',
    name: 'CZK',
  },
  {
    id: '7',
    name: 'RON',
  },
  {
    id: '8',
    name: 'HUF',
  },
  {
    id: '9',
    name: 'HRK',
  },
  {
    id: '10',
    name: 'BGN',
  },
  {
    id: '11',
    name: 'DKK',
  },
  {
    id: '12',
    name: 'GBP',
  },
  {
    id: '13',
    name: 'RSD',
  },
  {
    id: '14',
    name: 'RUB',
  },
  {
    id: '15',
    name: 'TRY',
  },
  {
    id: '16',
    name: 'INNA',
  },
];
