<template>
  <div>
    <form-section>
      <div class="information-div">
        <v-icon
          color="primary lighten-1"
          class="px-5"
        >
          mdi-information-outline
        </v-icon>
        <span class="py-2">
          {{ $t('courierSet.authorization.appRegistration', { courier: 'UPS' }) }}.
          <br />
          <span class="text--text-lighten1"
            >*{{ $t('courierSet.authorization.singleRegistrationInfo') }}.</span
          >
        </span>
      </div>
      <div style="text-align: end; padding: 5px 0px">
        <span class="link">
          <a
            href="https://developer.ups.com/apps/"
            target="_blank"
            >https://developer.ups.com/apps/</a
          >
        </span>
      </div>
      <v-divider class="mb-1" />
      <div class="redirection-info-div">
        <span class="text--text-lighten1">
          {{ $t('courierSet.redirectURL') }}:
          <br />
          <span class="text--text caption">{{ redirectUri }}</span>
        </span>
        <v-btn
          elevation="0"
          color="white"
          class="pa-0"
          @click="copyToClipboard(redirectUri)"
        >
          <v-icon
            class="pa-0"
            color="primary lighten-1"
          >
            mdi-content-copy
          </v-icon>
        </v-btn>
      </div>
    </form-section>

    <form-section :title="$t('courierSet.authData')">
      <v-row>
        <v-col cols="6">
          <text-field
            v-model="getConfigurationData.apiClientId"
            :title="$t('courierSet.clientId')"
            rules="required"
            :disabled="isAuthorized"
          />
        </v-col>
        <v-col cols="6">
          <text-field
            v-model="getConfigurationData.apiClientSecret"
            :title="$t('courierSet.clientSecret')"
            type="password"
            rules="required"
            :disabled="isAuthorized"
          />
        </v-col>
      </v-row>

      <v-row v-if="!isAuthorized">
        <v-col cols="6">
          <v-btn
            color="primary lighten-1"
            elevation="0"
            width="100%"
            @click="onUpsAuthorization"
          >
            ZALOGUJ DO UPS*
          </v-btn>
          <span class="d-block text--primary-lighten1">*Wymagane do rozpoczęcia konfiguracji.</span>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          <div class="authorization-info">
            <v-icon
              color="success"
              class="pr-2"
            >
              mdi-check-circle-outline
            </v-icon>
            <span>
              <span class="text--success font-weight-bold">Poprawna autoryzacja </span>
              danych logowania.
            </span>
          </div>
        </v-col>
      </v-row>
    </form-section>

    <form-section :title="$t('courierSet.setData')">
      <v-row>
        <v-col class="pb-0">
          <text-field
            v-model="getConfigurationData.configurationName"
            :title="$t('courierSet.configurationName')"
            rules="required|not_custom_shipment"
            :disabled="!isAuthorized"
          />
        </v-col>
        <v-col>
          <text-field
            v-model="getConfigurationData.id"
            :title="$t('courierSet.configurationId')"
            rules="required"
            :disabled="true"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <select-field
            v-model="getConfigurationData.courierCode"
            :title="$t('courierSet.deliverySpeditor')"
            rules="required"
            :filed-items="SHIPMENT_METHOD_CODES"
            disabled
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <text-field
            v-model="getConfigurationData.customerNumber"
            :title="$t('courierSet.accountNumber')"
            rules="required"
            :disabled="!isAuthorized"
          />
        </v-col>
      </v-row>
    </form-section>

    <form-section :title="$t('courierSet.serviceType')">
      <v-row>
        <v-col>
          <select-field
            v-model="getConfigurationData.serviceCode"
            :title="$t('courierSet.serviceType')"
            rules="required"
            :disabled="!isAuthorized"
            :filed-items="serviceTypes"
          />
        </v-col>
      </v-row>
    </form-section>

    <v-dialog
      v-model="isUpsAuthorizationVisible"
      width="788px"
      persistent
    >
      <UPSAuthorization
        :redirect-url="redirectUri"
        @@setAuthorizationData="setAuthorizationData"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { serviceTypes } from '@/constants/Speditors/UPS/ServiceTypes.js';
import { copyToClipboard } from '@/mixins/helper.js';
import { SHIPMENT_METHOD_CODES } from '@/constants/ShipmentMethodCodes.js';
import UPSAuthorization from '../UPSAuthorization.vue';

export default {
  components: { UPSAuthorization },
  props: {
    isNew: { type: Boolean, default: false },
    isAuthorized: { type: Boolean, default: false },
  },
  data() {
    return {
      SHIPMENT_METHOD_CODES,
      serviceTypes,
      isUpsAuthorizationVisible: false,
      environmentUrl: 'https://onlinetools.ups.com',
    };
  },
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
    getConfigurationData() {
      return this.getConfigurationSet();
    },
    redirectUri() {
      return 'https://spedition.nubowms.pl/UPS/new';
    },
    isUpsAuthorizationCodeGenerated() {
      return !!this.$route.query.code;
    },
  },
  mounted() {
    this.isUpsAuthorizationVisible = this.isUpsAuthorizationCodeGenerated;
  },
  methods: {
    copyToClipboard,
    onUpsAuthorization() {
      window.location.href =
        `${this.environmentUrl}/security/v1/oauth/authorize?` +
        `client_id=${this.getConfigurationData.apiClientId}` +
        '&response_type=code' +
        `&redirect_uri=${this.redirectUri}`;
    },
    setAuthorizationData(data) {
      const { accessToken, refreshToken, clientSecret, clientId } = data;
      this.getConfigurationData.apiClientId = clientId;
      this.getConfigurationData.apiClientSecret = clientSecret;
      this.getConfigurationData.token = accessToken;
      this.getConfigurationData.refreshToken = refreshToken;
      this.getConfigurationData.url = this.environmentUrl;
      this.isUpsAuthorizationVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
span {
  font-size: 12px;
  padding-bottom: 10px;
}

.authorization-info {
  background-color: $success-lighten2 !important;
  padding: 8px 16px;
  margin-bottom: 8px;
  border-radius: 4px;

  span {
    font-size: 14px;
  }
}
.information-div {
  display: flex;
  align-content: space-between;
  justify-items: center;
  background-color: $primary-lighten4;
  border-left: solid 4px $primary-lighten1;
  border-radius: 4px;
  padding-top: 8px;
  font-size: 14px;
  font-weight: 400;
  text-align: start;
}

.link a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.redirection-info-div {
  text-align: start;
  display: flex;
  justify-content: space-between;
  align-items: end;
}
</style>
