import { OutputImageProperties } from './OutputImageProperties';
import { Content } from './Content';
import { OnDemandDelivery } from './OnDemandDelivery';
import { AdditionalInformation } from './AdditionalInformation';

export const DHLExpressDefaultValues = {
  clientEntityState: 4,
  tenantId: '',
  id: '',
  pickupIsRequested: false,
  pickupCloseTime: '',
  pickupLocation: '',
  pickupSpecialInstructions: [],
  productCode: '',
  localProductCode: '',
  getRateEstimates: false,
  accounts: [],
  outputImageProperties: OutputImageProperties,
  customerReferences: [],
  identifiers: [],
  content: Content,
  documentImages: [],
  onDemandDelivery: OnDemandDelivery,
  requestOndemandDeliveryURL: false,
  notification: [],
  prepaidCharges: [],
  getTransliteratedResponse: false,
  estimatedDeliveryDate: AdditionalInformation,
  getAdditionalInformation: [],
  parentShipment: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    productCode: '',
    packagesCount: 0,
  },
};
