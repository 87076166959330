const config = require('../oidc/index');

export const items = [
  { title: 'Pulpit', icon: 'mdi-view-dashboard-outline', to: `${config.oidcSettings.nubo}` },
  {
    title: 'Zadania magazynowe',
    icon: 'mdi-briefcase-variant-outline',
    to: `${config.oidcSettings.nubo}zadania-magazynowe/pakowania`,
  },
  {
    title: 'Wydania',
    icon: 'mdi-cube-send',
    to: `${config.oidcSettings.nubo}wydania/wszystkie`,
  },
  {
    title: 'Przyjęcia',
    icon: 'mdi-truck-fast-outline',
    to: `${config.oidcSettings.nubo}przyjecia/do-realizacji`,
  },
  {
    title: 'Inwentaryzacja',
    icon: 'mdi-list-status',
    to: `${config.oidcSettings.nubo}inwentaryzacja-dokumenty`,
  },
  {
    title: 'Kartoteki',
    icon: 'mdi-book-open-page-variant-outline',
    to: `${config.oidcSettings.nubo}kartoteki/towary`,
  },
  {
    title: 'Stan magazynu',
    icon: 'mdi-chart-box-outline',
    to: `${config.oidcSettings.nubo}stan-magazynu`,
  },
  {
    title: 'Historia wydruków',
    icon: 'mdi-printer-pos-check-outline',
    to: `${config.oidcSettings.nubo}historia-wydrukow`,
  },
  {
    title: 'Listy przewozowe',
    icon: 'mdi-text-box-outline',
    to: `${config.oidcSettings.nubo}listy-przewozowe/wygenerowane-lp`,
  },
  {
    title: 'Raporty',
    icon: 'mdi-book-edit-outline',
    to: `${config.oidcSettings.nubo}raporty/towar`,
  },
  {
    title: 'Konfiguracja globalna',
    icon: 'mdi-cog-outline',
    to: '/konfiguracja',
    active: false,
    items: [
      {
        title: 'Moja firma',
        icon: '',
        to: `${config.oidcSettings.nubo}konfiguracja/organizacja`,
      },
      {
        title: 'Użytkownicy',
        icon: '',
        to: `${config.oidcSettings.nubo}konfiguracja/uzytkownicy`,
      },
      {
        title: 'Importy',
        icon: '',
        to: `${config.oidcSettings.nubo}konfiguracja/importy`,
      },
      {
        title: 'Integracje / API',
        icon: '',
        to: `${config.oidcSettings.nubo}konfiguracja/integracje-api`,
        permissionRole: {
          allow: ['TENANT_ADMIN'],
        },
      },
    ],
  },
  {
    title: 'Konfiguracja magazynu',
    icon: 'mdi-home-edit-outline',
    active: false,
    items: [
      {
        title: 'Ogólne',
        icon: '',
        to: `${config.oidcSettings.nubo}konfiguracja-magazynu/ogolne`,
      },
      {
        title: 'Procesy',
        icon: '',
        to: `${config.oidcSettings.nubo}konfiguracja-magazynu/procesy/wydania`,
      },
      {
        title: 'Drukowanie',
        icon: '',
        to: `${config.oidcSettings.nubo}konfiguracja-magazynu/drukowanie/drukarki`,
      },
      {
        title: 'System składowania',
        icon: '',
        to: `${config.oidcSettings.nubo}konfiguracja-magazynu/system-skladowania/lokalizacja`,
      },
    ],
  },
  {
    title: 'Konfiguracja wysyłki',
    icon: 'mdi-cube-outline',
    active: false,
    items: [
      {
        title: 'Sposoby wysyłki',
        icon: '',
        to: `${config.oidcSettings.speditionURL}`,
      },
      {
        title: 'Opakowania wysyłkowe',
        icon: '',
        to: `${config.oidcSettings.nubo}konfiguracja-wysylki/wysylka/opakowania-wysylkowe`,
      },
      {
        title: 'Mapowanie',
        icon: '',
        to: `${config.oidcSettings.nubo}konfiguracja-wysylki/wysylka/mapowanie`,
      },
    ],
  },
];
