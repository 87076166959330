import { createApiClient } from '@/services/_auth_interceptors';

const config = require('../../oidc/index');

const apiClient = createApiClient(config.oidcSettings.spedtionConfigurationURL);

export const upsAuthorizationService = {
  getUpsAuthorizationToken(payload) {
    return apiClient.post('UpsAuth/token', payload);
  },
};
