export const adrService = {
  clientEntityState: 4,
  tenantId: '',
  id: '',
  packageQuantity: '',
  adrAmount: '',
  packageGroup: '',
  unCode: '',
  packageCode: '',
  adrUnit: '',
  adrTechnicalName: '',
};
