<template>
  <vee-field
    v-slot="{ errors }"
    :rules="rules"
  >
    <v-select
      v-model="model"
      v-bind="$attrs"
      :label="requredLabel"
      :items="filedItems"
      :hint="hint"
      :disabled="disabled"
      :error-messages="errors"
      :menu-props="{ bottom: true, offsetY: true }"
      item-text="name"
      item-value="id"
      class="pa-0"
      :no-data-text="$t('common.noData')"
      dense
      outlined
      persistent-placeholder
      @input="update()"
    />
  </vee-field>
</template>
<script>
export default {
  props: {
    title: { type: String, default: null },
    filedItems: { type: Array, default: null },
    hint: { type: String, default: null },
    rules: { type: String, default: '' },
    disabled: { type: Boolean, dafult: false },
    fieldId: { type: String, default: 'false' },
    value: {
      type: [String, Number, Array, Object],
      default: null,
    },
  },
  data: () => ({
    model: null,
  }),
  computed: {
    requredLabel() {
      return this.rules.includes('required') ? `${this.title} *` : this.title;
    },
  },
  watch: {
    value(newVal) {
      this.model = newVal;
    },
  },
  mounted() {
    this.model = this.value;
  },
  methods: {
    update() {
      let inputValue = null;
      if (this.model?.name) {
        inputValue = this.model.name;
      } else {
        inputValue = this.model;
      }

      this.$emit('input', inputValue);

      this.$emit('@update');
    },
  },
};
</script>

<style scoped>
::v-deep .v-input__slot {
  background-color: white !important;
  max-width: 100%;
}

::v-deep.v-select .v-chip {
  background-color: white;
  max-width: 100%;
}
::v-deep.v-chip__content {
  background-color: white;
}
::v-deep.v-list {
  background-color: none;
}
</style>
