import Keycloak from 'keycloak-js';
import store from '@/store';
import { SET_KEYCLOAK_CLIENT } from '@/store/mutation-types';
import { UPDATE_OIDC_INFO } from '@/store/action-types';

const ENV = process.env.VUE_APP_BUILD_TARGET_ENVIRONMENT || 'local';

export const TOKEN_MIN_VALIDITY_SECONDS = 15;
export const keycloakClient = Keycloak(`/keycloak-config/keycloak_${ENV}.json`);

function updateOidcStore() {
  store.dispatch(`oidcStore/${UPDATE_OIDC_INFO}`);
}

function initKeycloak() {
  keycloakClient.onAuthSuccess = () => {
    updateOidcStore();
  };

  keycloakClient.onReady = () => {
    store.commit(`oidcStore/${SET_KEYCLOAK_CLIENT}`, keycloakClient);
    updateOidcStore();
  };

  keycloakClient.onAuthError = () => {
    updateOidcStore();
  };

  keycloakClient.onAuthRefreshSuccess = () => {
    updateOidcStore();
  };

  keycloakClient.onAuthRefreshError = () => {
    updateOidcStore();
  };

  keycloakClient.onAuthLogout = () => {
    updateOidcStore();
  };

  keycloakClient.onTokenExpired = () => {
    updateOidcStore();
  };

  setInterval(async () => {
    await keycloakClient.updateToken(TOKEN_MIN_VALIDITY_SECONDS);
  }, 5 * 1000);

  return keycloakClient.init({
    onLoad: 'login-required',
  });
}

export { initKeycloak };
