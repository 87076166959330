export const suusAdditionalServices = [
  {
    id: 1,
    serviceId: 'COD',
    name: 'Pobranie',
    description: 'Pobranie za przesyłkę.',
    remark: '',
  },
  {
    id: 2,
    serviceId: 'INSURANCE',
    name: 'Ubezpieczenie',
    description: 'Ubezpieczenie przesyłki.',
    remark: '',
  },
  {
    id: 4,
    serviceId: 'ROD',
    name: 'Zwrot dokumentów',
    description: '',
    remark: '',
  },
  {
    id: 100,
    serviceId: 'HOUR_DELIVERY',
    name: 'Dostawa na godzinę',
    description: '',
    remark: '',
  },
  {
    id: 113,
    serviceId: 'UNUSUAL_DELIVERY_HOURS',
    name: 'Niestandardowe godziny dostawy',
    description: '',
    remark: '',
  },
  {
    id: 200,
    serviceId: 'EMAIL_NOTIFICATION_SENDER',
    name: 'Powiadomienie nadawcy',
    description: '',
    remark: '',
  },
  {
    id: 201,
    serviceId: 'EMAIL_NOTIFICATION_RECEIVER',
    name: 'Powiadomienie odbiorcy',
    description: '',
    remark: '',
  },
  {
    id: 203,
    serviceId: 'SMS_NOTIFICATION_RECEIVER',
    name: 'Awizacja SMS do odbiorcy',
    description: '',
    remark: '',
  },
  {
    id: 403,
    serviceId: 'BRINGING_UPSTAIRS',
    name: 'Wniesienie',
    description: '',
    remark: '',
  },
];
