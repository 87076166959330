export const header = {
  clientEntityState: 4,
  tenantId: '',
  id: '',
  reference: '',
  loadingDate: '',
  unloadingDate: '',
  descriptionOfGoods: '',
  remarks: '',
  incoterms: '',
  costGroup: '',
  freight: '',
  currency: '',
  category: '',
  orderType: '',
};
